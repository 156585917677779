<template>
  <div v-if="chatvisible" class="chat">
    <div class="chatinner">
      <div style="display: flex">
        <h1 style="margin: 0;margin-bottom:20px; flex-grow: 1">Chat</h1>
        <div @click="close">
          <svg style="width: 24px;height: 24px" fill="currentColor" viewBox="0 0 20 20"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"></path>
          </svg>
        </div>
      </div>


      <div v-if="!isConnected">
        <label style="display: block">
          Name:
          <input v-model="usrName" placeholder="Dein Name" @keyup.enter="logIn">
        </label>
        <label style="display: block;font-size: 14px">
          <p>Dieses Chatformular kann für die elektronische Kontaktaufnahme
            genutzt werden. Nimmt ein Nutzer diese Möglichkeit wahr, so werden die in der Eingabemaske eingegeben
            Daten an das kontaktierte Unternehmen übermittelt und gespeichert. Diese Daten sind: Name, Anliegen
            (Chatverlauf). Zum Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert: IP-Adresse
            und Datum und Uhrzeit des Absendens.
          </p>
          <p>
            <input v-model="dschecked" type="checkbox">
            Mit der Nutzung der Chatfunktion stimme ich der Verarbeitung meiner Daten im oben dargestellten Rahmen zu.
          </p>
        </label>
        <label style="display: block;font-size: 14px">
          <p>
            <input v-model="dschecked2" type="checkbox">

            Die
            <router-link to="/Datenschutz" style="font-weight: bold">Datenschutzerklärung</router-link>
            habe ich zur Kenntnis genommen.
          </p>
        </label>
        <button @click="logIn" :disabled="!dschecked || !dschecked2">Anmelden</button>
      </div>
      <div v-if="isConnected" class="rooms">
        <div v-for="(firm, index) in visivibleRooms" :key="index"
             class="users"
             :class="{ active: currentRoom == firm.connectionid }"

        >
          <a @click="join(firm.connectionid)">{{ firm.name }} ({{ countRoomMessages(firm.connectionid) }})</a>
          <a @click="leave(firm.connectionid)">
            <svg style="width: 20px;height: 20px" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"></path>
            </svg>
          </a>
        </div>
      </div>
      <div v-if="isConnected && currentRoom" class="chatdisply">
        <div v-for="(message, index) in currentRoomMessages" :key="index">
          <p class="message"><strong>{{ message.name }}:</strong> {{ message.message }}</p>
        </div>

        <form class="messageenter" @submit.prevent="sendMessage">
          <input v-model="message" type="text" placeholder="Deine Nachricht">

          <button @click="sendMessage">Abschicken</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['chatvisible'],
  data() {
    return {
      myuuid: null,
      isConnected: false,
      usrName: '',
      dschecked: false,
      dschecked2: false,
      message: '',
      currentRoom: null,
      firms: [],
      visibleconnectionsids: [],
      messages: [],
    }
  },
  computed: {
    currentRoomMessages: function () {
      const self = this
      return this.messages.filter(
        c => c.from == self.currentRoom || c.msgFor == self.currentRoom,
      )

    },
    visivibleRooms: function () {
      const self = this

      return this.firms.filter(
        firm => self.visibleconnectionsids.includes(firm.connectionid)
      )

    },
  },
  watch: {
    firms: function () {
      if (this.visivibleRooms.length === 1
        && this.usrName != ''
        && this.currentRoom != this.visivibleRooms[0].connectionid) {
        //console.log('join', this.visivibleRooms[0].connectionid)
        this.changeRoom(this.visivibleRooms[0].connectionid)
        //  this.join(this.firms[0].firmaid)
      }
    },

  },
  created() {
    const self = this
    this.myuuid = this.generateUUID()
    this.$socket.onopen = function () {
      self.$socket.sendObj({
        type: 'login',
        name: self.usrName,
        userid: self.myuuid,
      })
    }
    this.$socket.onmessage = function (fromserver) {

      let jsonMessage = JSON.parse(fromserver.data)
      //console.log(jsonMessage)

      if (jsonMessage.type === 'onlineUsers') {
        //console.log(jsonMessage.onlineUsers)
        self.firms = Object.values(jsonMessage.onlineUsers)
      } else if (jsonMessage.type === 'message') {
        if (this.visibleconnectionsids && !this.visibleconnectionsids.includes(jsonMessage.from))
          this.visibleconnectionsids.push(jsonMessage.from)

        self.messages.push(jsonMessage)
      }
    }
    //console.log(this.$socket)

  },
  methods: {
    changeRoom(roomid) {
      //console.log(roomid)
      this.currentRoom = roomid
    },
    close: function () {
      this.$root.chatvisible = false
    },
    countRoomMessages: function (id) {
      return this.messages.filter(
        c => c.from == id || c.msgFor == id,
      ).length
    },
    generateUUID: function () { // Public Domain/MIT
      var d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now() //use high-precision timer if available
      }
      var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })

      return newGuid
    },
    leave(connectionid) {
      /*
      this.$socket.sendObj({
        type: 'leave',
        msgFor: connectionid,
      })*/

      // remove connectionid
      this.visibleconnectionsids = this.visibleconnectionsids.filter(item => item !== connectionid)

      if (this.visibleconnectionsids[0])
        this.changeRoom(this.visibleconnectionsids[0])
      else
        this.close()
    },
    join(connectionid) {
      this.$socket.sendObj({
        type: 'join',
        msgFor: connectionid,
      })
      if (!this.visibleconnectionsids.includes(connectionid))
        this.visibleconnectionsids.push(connectionid)


      this.changeRoom(connectionid)
    },
    joinViaFirmaId(firmaid) {
      let connectionid = this.firms.find(firm => firm.firmaid === firmaid).connectionid;
      this.$socket.sendObj({
        type: 'join',
        msgFor: connectionid,
      })
      this.visibleconnectionsids.push(connectionid)
      this.changeRoom(connectionid)
    },
    logIn() {
      if (!this.dschecked) return
      if (!this.dschecked2) return
      window._paq.push(['trackEvent', 'click', 'Chat', 'login'  , '1'])

      //console.log(this.$socket)
      this.$socket.sendObj({
        type: 'login',
        name: this.usrName,
        userid: this.myuuid,
      })

      this.isConnected = true
    },
    sendMessage: function () {
      //console.log(this.message)
      // $socket is [WebSocket](https://developer.mozilla.org/en-US/docs/Web/API/WebSocket) instance
      if (this.message.length === 0) {
        return
      }

      var msg = {}
      msg['type'] = 'message'
      msg['name'] = this.usrName
      msg['message'] = this.message
      msg['msgFor'] = this.currentRoom
      this.messages.push(msg)
      this.$socket.sendObj(msg)
      this.message = ''
    },
  },
}
</script>
<style lang="scss" scoped>
.chat {
  z-index: 99999;
  position: fixed;
  left: 0;
  right: 0;
  max-height: 100vh;
}

.chatinner {
  max-height: 100vh;
  overflow: scroll;
  margin: 0 auto;
  background-color: #fcfcfc;
  width: 300px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
}

.rooms {
  border-bottom: 1px solid #444;
}

.chatdisply {
  padding-top: 10px;
  padding-bottom: 10px;

  .message {
    margin: 0;
    margin-bottom: 5px;
  }

  .messageenter {
    display: flex;
    margin-top: 5px;

    input[type=text] {
      flex-grow: 1;
    }
  }
}

.users {
  cursor: pointer;
  display: inline-block;
  padding: 3px 10px;
  background-color: #bebebe;
  margin-right: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.users.active {
  background-color: #444;
  color: #fff;
  font-weight: bold;
}

.users a {
  font-size: 14px;
  color: #fff;

}

.button {
  background-color: #444444;
  color: #fff;
  border-radius: 999px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
}
</style>
