<template>
  <div class="home">
    <Header/>
    <div class="ausrichtung">
      <div class="box" style="background-color: transparent">
        <p style="text-align: center;"><label class="ueberschrift">Der virtuelle Insider</label></p>
        <p>
          Willkommen zum virtuellen INSIDERTREFF – deiner Messe für Ausbildung und Studium im Landkreis Görlitz.
          <br><br>
          Wir freuen uns, dass du hier bist! Auf dem virtuellen Messegelände erwarten dich über 150 Aussteller und die
          Hochschule Zittau/Görlitz präsentiert ihre 6 Fakultäten in der "Halle des Studierens".
          <br><br>
          Entdecke spannende Ausbildungsbetriebe und Studienangebote in unserer Region bei deinem Rundgang durch die
          Messehallen. Bei Fragen findest du die Kontaktdaten deines Ansprechpartners direkt am Stand.
          <br><br>
          Viel Spaß beim Entdecken deiner Möglichkeiten!

        </p>
      </div>
      <div class="">
        <div class="box hallenbox">

          <h2>Hallen:</h2>
          <p>Hier kannst du einen virtuellen 3D-Rundgang durch die Hallen machen – fühle dich wie auf einem realen
            Messegelände.
          </p>
          <ul style="display: flex;flex-wrap: wrap;gap:10px">
            <li v-for="Halle in hallen" v-bind:key="Halle.id"
                style="flex-grow: 1;text-align: center;border-radius: 50px;"
                v-bind:style="{backgroundColor: Halle.mcolor}">
              <router-link :to="{ name: 'Hall', params: { id: Halle.id }}">
                {{ Halle.name }}

              </router-link>
            </li>
          </ul>

        </div>
        <div class="box hallenbox">

          <h2>Klassische Ansicht:</h2>
          <p>Du magst dich lieber durch die Stände klicken? Dann bist du hier richtig!
          </p>
          <ul>
            <li style="background-color: rgb(250, 155,0);max-width: 250px;text-align: center;border-radius: 50px;">
              <router-link :to="{ name: 'Stand', params: { stand: '1' }}">
                Klassische Ansicht
              </router-link>
            </li>
          </ul>
        </div>
        <div class="box ausstellerbox">
          <h2 style="margin-bottom: 10px">Aussteller:</h2><br>
          <input v-model="ausstellersearch" type="text" class="search" placeholder="Aussteller suchen..."
                 style="margin-bottom: 10px"
          >
          <span style="margin-left: 20px">Halle: </span><select v-model="hallesearch">
          <option :value="null">
            --Alle--
          </option>
          <option v-for="Halle in hallen" v-bind:key="Halle.id" :value="Halle.id"
                  style="padding: 10px;color: white"
                  v-bind:style="{backgroundColor: Halle.mcolor}">
            {{ Halle.name }}

          </option>
        </select>
          <ul style="display: flex;flex-wrap: wrap;gap: 10px;">
            <li v-for="firma in aussteller" :key="firma.id"
                style=""
                :style="{ backgroundColor: hallcolor(firma) }">
              <router-link :to="{ name: 'Hall', params: { id: firma.Halle, stand:firma.id }}">
                <div
                  style="background-color: white;padding: 10px; ">
                  <img :src="firma.Logo" :alt="firma.Name" style="width: 70px;">
                </div>
                <h2 style="font-weight:400;color: #fff;font-size: 14px;padding-top: 10px;hyphens: auto">{{
                    firma.Firma
                  }}</h2>

                <div v-if="chatonline(firma)"
                     style="font-size:12px;display:inline-block;padding:2px 5px;background-color: #196c37;color: white;border-radius: 3px">
                  Live Chat
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import filter from 'lodash/filter'
import Header from '../components/header/header'

export default {
  name: 'Home',
  components: {Header},
  data() {
    return {
      ausstellersearch: '',
      hallesearch: null,
    }
  },
  computed: {
    aussteller() {
      let self = this

      return filter(this.$root.teilnehmer, function (tn) {
        return tn.Firma.toLowerCase().includes(self.ausstellersearch.toLowerCase())
          && (tn.Halle === self.hallesearch || self.hallesearch == null)
      })
    },
    countAussteller(id) {
      return this.$root.teilnehmer.filter(
        tn => parseInt(tn.Halle) === parseInt(id),
      ).length
    },
    hallen() {
      return this.$root.hallen
    },

  },
  methods: {
    chatonline(firm) {
      let firma = firm

      // console.log(this.$parent.$refs.chatwidget.firms)

      let firms = JSON.parse(JSON.stringify(this.$parent.$refs.chatwidget.firms))
      // console.log(firms)
      let valObj = firms.filter(function (elem) {
        return (parseInt(elem.firmaid) === parseInt(firma.id))
      })

      return valObj.length > 0

    },
    hallcolor(firm) {
      let firma = firm

      let hallen = JSON.parse(JSON.stringify(this.$root.hallen))

      let valObj = hallen.filter(function (halle) {

        return (parseInt(halle.id) === parseInt(firma.Halle))
      })
      if (valObj[0] && valObj[0].mcolor)
        return valObj[0].mcolor

    },
  }
}
</script>

<style scoped lang="scss">
.ueberschrift {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 50px;
  line-height: 20px;
  background-color: white;
  margin-top: 10px;
  vertical-align: middle;
  font-weight: normal;
  font-family: "Museo Slab W01_300";
  cursor: pointer;
  margin-bottom: 30px;
  padding: 10px 30px;
  font-size: 20px;
  border: 1px solid rgb(250, 155, 0);
  color: rgb(250, 155, 0);
}

.ausrichtung {
  max-width: 1040px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #196c37;
  background-color: white;
  padding: 6px 15px;
  color: #196c37;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 20px;
}

.search {
  border: 1px solid #196c37;
  background-color: white;
  background-image: url("../assets/INSIDERTREFF_Lupe.svg");
  background-repeat: no-repeat;
  padding: 6px 15px;
  padding-left: 40px;
  background-size: 20px 20px;
  background-position: 10px center;
  color: #196c37;
  font-size: 16px;
  line-height: 22px;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 20px;
}

.home {
  padding-bottom: 60px;
}

.box {
  padding: 20px;
  margin-right: 20px;
  margin-left: 20px;
  @media (max-width: 768px) {

    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;

  }

  h2 {
    margin-bottom: 5px;
  }

  p {
    margin-top: 0px;
    line-height: 28px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;

      border-radius: 0px;
      transition: all .2s;

      img {

        transition: all .2s;
      }

      &:hover {

        transform: scale(1.1);
        box-shadow: 0 0 5px rgba(0, 0, 0, .6);

        img {
          transform: scale(1.25);
        }
      }

      a {
        font-family: "Museo Slab W01_300", sans-serif;
        display: block;
        color: #333;
        padding: 1px;
      }
    }
  }

  &.hallenbox {


    ul {
      li {
        a {
          padding: 10px;
          color: #fff;
        }
      }
    }
  }

  &.ausstellerbox {


    ul {
      li {

        width: 19%;
        text-align: center;
        box-sizing: border-box;
        flex-grow: 1;
        @media (max-width: 1024px) {
          width: 25%;
        }
        @media (max-width: 768px) {
          width: 40%;
        }
        @media (max-width: 480px) {
          width: auto;
        }

        a {

        }
      }
    }
  }


  h2 {
    margin-top: 0;

  }
}


a {
  text-decoration: none;
}
</style>
