<template>
  <div class="header">
    <img src="../../assets/logo/Header.png"
         srcset="../../assets/logo/HeaderMobil.jpg 440w,
         ../../assets/logo/Header.png 600w"
         style="max-width: 100%">
  </div>
</template>

<script>
export default {}
</script>
