import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueNativeSock from 'vue-native-websocket'

import VueMatomo from 'vue-matomo'


Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://statistik.eno-intern.de/',
  siteId: 3,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: true,

  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: true,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: false,

})


Vue.use(VueNativeSock, process.env.VUE_APP_WSS_ENDPOINT, {
  reconnection: false, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 0, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
  format: 'json',
})

Vue.config.ignoredElements = [
  'a-box',
  'a-scene',
  'a-assets',
  // Use a `RegExp` to ignore all elements that start with "ion-"
  // 2.5+ only
  /^a-/,
]
new Vue({
  data: {
    loading: false,
    chatvisible: false,
    dsvisible: true,
    teilnehmer: [],
    hallen: [
      {id: 1, name: 'Beratung | Information', mcolor: '#201175'},
      {id: 2, name: 'Bildungseinrichtung', mcolor: '#8b2aa7'},
      {id: 3, name: 'Büro | Dienstleistung | Öffentlicher Dienst I', mcolor: '#d50072'},
      {id: 31, name: 'Büro | Dienstleistung | Öffentlicher Dienst II', mcolor: '#d50072'},
      {id: 32, name: 'Büro | Dienstleistung | Öffentlicher Dienst III', mcolor: '#d50072'},
      {id: 4, name: 'Ernährung | Landwirtschaft', mcolor: '#006b1c'},
      {id: 5, name: 'Freizeit | Tourismus', mcolor: '#de4200'},
      {id: 6, name: 'Gesundheit | Pflege', mcolor: '#8bb600'},
      {id: 7, name: 'Handel', mcolor: '#f7b300'},
      {id: 8, name: 'Handwerk', mcolor: '#0077c9'},
      {id: 9, name: 'Industrie I', mcolor: '#86868a'},
      {id: 91, name: 'Industrie II', mcolor: '#86868a'},
      {id: 92, name: 'Industrie III', mcolor: '#86868a'},
      {id: 11, name: 'IT / Elektrotechnik / Elektronik', mcolor: '#d70024'},
      {id: 10, name: 'Halle des Studierens - Hochschule Zittau/Görlitz', mcolor: '#444444'},
    ],
  },
  mounted() {

    this.loading = true
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + 'firma/apimesse?2023=1')
      .then(response => {
        //console.log(response.data);
        this.teilnehmer = response.data
      })
      .catch(error => {
        console.log(error)
        alert(error)
        this.errored = true
      })
      .finally(() => this.loading = false)

  },
  router,
  render: h => h(App),
}).$mount('#app')
